.btn {
  padding: 8.5px 38px;

  transition: opacity 0.1s linear;

  border-radius: 8px;

  font-size: 14px;

  &:hover {
    opacity: 0.8;
    box-shadow: 0 8px 12px rgb(67 149 157 / 0.2);
  }

  &:focus {
    outline: none;

    box-shadow: none !important;
  }

  &:disabled {
    border: none;
  }

  &.btn-danger-light {
    border-color: transparent;

    background: rgb(255 99 97 / 0.1);

    color: $color-error;
  }

  &.btn-primary {
    border-color: $color-primary;

    background: $color-primary;

    color: $color-white;

    &[disabled] {
      border-color: $color-input-gray;

      background: $color-input-gray;

      color: $color-muted-gray;

      cursor: not-allowed !important;
    }
  }

  &.btn-secondary {
    border-color: $color-white;

    background: $color-white;

    color: $color-primary;
  }

  &.btn-blur {
    background: rgb(230 230 235 / 0.2);

    color: $color-white;
  }

  &.btn-primary-light {
    border-color: $color-primary-light;

    background: $color-primary-light;

    color: $color-primary;
  }

  &.btn-warning {
    border-color: transparent;

    background: rgb(242 165 83 / 0.1);

    color: #f2a553;

    &:hover,
    &:focus {
      background: rgb(242 165 83 / 0.3);
    }
  }
}

.btn.btn-vrbo {
  background: $color-vrbo;

  color: $color-white;
}

.btn.btn-booking {
  background: $color-booking;

  color: $color-white;
}

.btn.btn-airbnb {
  background: $color-airbnb;

  color: $color-white;
}

.btn-link {
  transition: opacity 0.1s linear;

  font-size: 14px;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &.btn-primary {
    background: transparent;

    color: $color-primary;
  }
}

.more-btn {
  width: 32px;
  height: 32px;
  padding: 0;

  border: none;
  border-radius: 4px;

  background: transparent;

  color: $color-primary;
  font-size: 16px;
  line-height: 33px;
  text-align: center;

  &:hover,
  &:focus {
    background: $color-light-primary;
  }

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px;
    line-height: 24px;
  }
}

.btn-link {
  &.btn-danger {
    border-color: transparent;

    background: transparent;

    color: $color-error;
    font-weight: 500;
  }

  &.btn-warning {
    border-color: transparent;

    background: transparent;

    color: $color-orange;
    font-weight: 500;

    &:hover,
    &:focus {
      border-color: transparent;

      background: transparent;
    }
  }
}

.btn-circle {
  width: 32px;
  height: 32px;
  padding: 0;

  border-radius: 50%;

  line-height: 35px;
  text-align: center;

  &.size-20 {
    width: 20px;
    height: 20px;

    font-size: 8px;
    line-height: 21px;
  }

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 8px;
    line-height: 24px;
  }

  &.x24 {
    width: 24px;
    height: 24px;

    font-size: 9px;
    line-height: 24px;
  }
}

.btn-rouneded {
  width: 24px;
  height: 24px;
  padding: 0;

  border-radius: 4px;

  line-height: 25px;
  text-align: center;
}

.btn {
  &.btn-has-dropdown {
    padding-right: 8px;
  }

  &.btn-primary-light-dark {
    background: rgb(225 238 240 / 1);

    color: rgb(67 149 157 / 1);
  }

  &.btn-primary {
    .btn-circle {
      transition: all 0.3s ease-in-out;

      background: rgb(255 255 255 / 0.1);

      color: $color-white;
    }

    &:focus {
      .btn-circle {
        transform: rotate(180deg);

        background: $color-white;

        color: $color-primary;
      }
    }
  }
}

.btn.dropdown-open {
  background: $color-primary;

  color: $color-white;

  .hosty-icon-chevron-down {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.btn-transparent {
  border: none;

  background: transparent;
}

#titlediv {
  position: relative;
  left: -140px;
  width: 15px;
  padding: 0 0 0 10px;

  background: $color-green-dark;
  box-shadow: none;

  color: $color-white;
  font-size: 14px;
}

#zsiq_maintitle {
  position: absolute;
  z-index: 2;
  left: 360px;
  padding-bottom: 20px;
  padding-left: 2px;

  opacity: 1;
  box-shadow: none;

  color: $color-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  text-decoration: none;
}

#zsiq_byline {
  color: $color-green-dark;
}

#zsiq_float {
  position: absolute;
  top: 560px;
  left: -124px;
  align-items: center;

  background: $color-green-dark;

  color: $color-white;
  font-size: 14px;
  font-weight: normal;
}

.zsiq_ellips {
  font-weight: normal;
}

#zsiq_agtpic {
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -95px;
  width: 22px;
  height: 45px;
  padding-right: 0;

  border: 1px solid $color-green-dark;
  border-radius: 8px;

  background: $color-green-dark;

  .zsiq_user .siqico-chat::before {
    position: absolute;
    width: 10px;
    height: 10px;

    background: $color-primary;
  }
}

#zsiq_agtpic:hover {
  box-shadow: none;
}

.zsiq_flt_rel {
  position: relative;
  left: -60px;

  background-color: $color-green-dark !important;
}

.zsiq_flt_rel:hover {
  box-shadow: none;
}

.zsiq_floatmain {
  position: absolute;
  top: -50px;
  width: 5px;
  height: 5px;

  background: $color-primary;
}

#zsiqscript {
  width: 5px;
  height: 5px;

  background: $color-primary;
}

.siqembed {
  margin-left: 225px;

  .sidebar-collapsed & {
    margin-left: 75px;
  }
}

.layout-mobile div.siqembed[embedtheme] {
  margin-left: 0;
  padding: 10px 10px 0 !important;

  background: rgb(0 0 0 / 0.74);
}

.general-button {
  padding: 8.5px 38px;

  transition: opacity 0.1s linear;

  border-radius: 8px;

  font-size: 14px;

  &.button-primary {
    border: none;

    background: $color-primary;

    color: $color-white;
  }

  &.button-blur {
    background: rgb(230 230 235 / 0.2);

    color: $color-white;
  }

  &.button-secondary {
    border: none;

    background: $color-white;

    color: $color-primary;
  }

  &.button-warning {
    border-color: transparent;

    background: rgb(242 165 83 / 0.1);

    color: #f2a553;

    &:hover,
    &:focus {
      background: rgb(242 165 83 / 0.3);
    }
  }
}

.button-link {
  transition: opacity 0.1s linear;

  color: $color-primary;
  font-size: 14px;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
