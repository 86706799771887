$color-white: #ffffff;
$color-light-gray: #f0f2f2;
$color-medium-gray: #d7dedf;
$color-divider: #e6e6eb;
$color-input-gray: #e6e6eb;
$color-muted-gray: #849192;
$color-primary: #43959d;
$color-black: #212526;
$color-dark: #041011;
$color-orange: #f2a553;
$color-primary-light: #f2f7f8;
$color-vrbo: #15326f;
$color-homeaway: #116db3;
$color-booking: #00429f;
$color-airbnb: #ff5a5f;
$color-error: #ff6361;
$color-error-light: rgb(255 99 97 / 0.1);
$color-green-dark: #005159;
$color-light-primary: #f5f9fa;
$bg-disabled: #f8fbfc;
$btn-font-weight: 500;
