.m-t-40 {
  margin-top: 40px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-22 {
  margin-bottom: 22px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-10 {
  margin-right: 12px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-10 {
  margin-left: 8px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-r-16 {
  margin-right: 16px;
}

/**
 * Paddings
 */
.p-t-0 {
  padding-top: 0 !important;
}

.p-t-40 {
  padding-top: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-18 {
  padding-top: 18px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-4 {
  padding-top: 4px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-11 {
  padding-top: 11px !important;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-11 {
  padding-bottom: 11px !important;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-6 {
  padding-left: 6px;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-24 {
  padding-right: 24px !important;
}

.p-8 {
  padding: 8px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-12 {
  padding-left: 12px !important;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-6 {
  padding-right: 6px;
}

.p-l-13 {
  padding-left: 13px;
}

.p-r-13 {
  padding-right: 13px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-44 {
  padding-left: 44px;
}

.p-r-44 {
  padding-right: 44px;
}

.p-l-4 {
  padding-left: 4px;
}

.p-l-6 {
  padding-left: 6px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-r-12 {
  padding-right: 12px !important;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-54 {
  padding-right: 54px;
}

.p-l-16 {
  padding-left: 16px;
}

.list-p-8 {
  & > * {
    display: block;
    margin-bottom: 8px;
  }
}

.horizontal-list-p-8 {
  margin-right: -8px;
  margin-left: -8px;
  & > * {
    margin-right: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
  }
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-30 {
  padding: 30px;
}

.m-r-60 {
  margin-right: 60px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-b-6 {
  padding-bottom: 6px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-l-16 {
  margin-left: 16px;
}
