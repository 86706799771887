@import 'partials/fonts';
@import 'partials/icons';
@import 'partials/typography';
@import 'partials/offsets';
@import 'partials/buttons';
@import 'partials/forms';
@import 'partials/utils';
@import 'partials/modals';
@import 'partials/alerts';
@import 'variables';

* {
  font-family: 'Rubik', sans-serif;
}
html {
  /* stylelint-disable length-zero-no-unit */
  --safe-top: 0px;
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;

  ::-webkit-scrollbar {
    position: absolute;
    width: 5px;
    height: 5px;

    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;

    background-color: #ced3d3;
  }
}

.tooltip {
  position: absolute;

  &-inner {
    border: 1px solid $color-divider;
    border-radius: 8px;

    background-color: $color-white;
    box-shadow: 0 8px 12px 0 rgb(230 230 235 / 0.5);

    color: $color-dark;
  }

  &-arrow {
    position: absolute;
  }

  &.show {
    opacity: 1;
  }

  &.top {
    .tooltip-inner {
      max-width: 320px;
    }

    .tooltip-arrow::before {
      border-top-color: $color-white;
    }
  }

  &.bottom {
    .tooltip-inner {
      padding: 12px;
    }

    .tooltip-arrow::before {
      top: -1px;
      left: 1px;

      border-bottom-color: $color-white;
    }
    .tooltip-arrow::after {
      content: '';

      position: absolute;
      z-index: -1;
      top: -9px;
      bottom: 0;
      left: -2px;
      width: 0;
      height: 0;

      border-width: 9px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: $color-divider;
    }

    &.vrbo-message-tip {
      .tooltip-inner {
        width: auto;
        max-width: 360px;

        color: $color-dark;
      }
    }

    &.sidebar {
      .tooltip-inner {
        width: 250px;
      }
    }
  }

  &.warn {
    .tooltip-inner {
      border-color: rgb(255 99 97 / 0.1);

      box-shadow: 0 8px 12px rgb(230 230 235 / 0.5);

      color: $color-error;
    }

    .tooltip-arrow::before {
      border-bottom-color: $color-white;
    }

    .tooltip-arrow::after {
      border-bottom-color: rgb(255 99 97 / 0.1);
    }
  }

  &.priority {
    .tooltip-inner {
      border-color: rgb(242 165 83 / 0.2);

      box-shadow: 0 8px 12px rgb(230 230 235 / 0.5);

      color: $color-orange;
    }

    .tooltip-arrow::before {
      border-bottom-color: $color-white;
    }

    .tooltip-arrow::after {
      border-bottom-color: rgb(242 165 83 / 0.2);
    }
  }

  &.sidebar {
    .tooltip-inner {
      max-width: 320px;
      padding: 12px;

      border: 1px solid rgb(67 149 157 / 0.2);
      border-radius: 8px;

      background-color: $color-white;
      box-shadow: 0 8px 12px 0 rgb(230 230 235 / 0.5);

      color: $color-primary;
    }

    .tooltip-arrow::before {
      border-right-color: $color-white;
    }

    .tooltip-arrow::after {
      border-right-color: rgb(67 149 157 / 0.2);
    }
  }

  &.payout {
    .tooltip-inner {
      max-width: 300px;
    }
  }

  &.trial {
    pointer-events: auto !important;

    .tooltip-inner {
      padding: 0;

      background-color: transparent;
    }

    .tooltip-arrow::before {
      border-right-color: #43959d;
    }

    &.bottom {
      .tooltip-inner {
        border: none;
      }

      .tooltip-arrow::before {
        border-right-color: transparent;
        border-bottom-color: #43959d;
      }

      .tooltip-arrow::after {
        border-bottom-color: #43959d;
      }
    }

    &-error {
      .tooltip-arrow::before {
        border-right-color: #ff6361;
      }

      &.bottom {
        .tooltip-arrow::before {
          border-bottom-color: #ff6361;
        }

        .tooltip-arrow::after {
          border-bottom-color: #ff6361;
        }
      }
    }
  }

  &.channel-status-label {
    .tooltip-arrow {
      display: none;
    }

    .tooltip-inner {
      padding: 4px 12px;

      border: 1px solid $color-divider;
      border-radius: 4px;

      background-color: white;

      color: $color-muted-gray;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  &.navigation {
    &.bottom {
      .tooltip-arrow::after {
        border-bottom-color: rgb(67 149 157 / 0.2);
      }
    }

    .tooltip-inner {
      border: 1px solid rgb(67 149 157 / 0.2);

      color: $color-primary;
    }
  }
}

.popover {
  border-radius: 8px;
  border-color: $color-divider;

  .arrow::before {
    border-bottom-color: $color-divider;
  }

  &.error-message-popover {
    border-color: $color-error;

    .popover-body {
      max-width: 200px;
      padding: 12px;

      border-radius: 8px;

      color: $color-error;
      text-align: center;

      .btn-link {
        padding: 0;

        border: none;

        background-color: transparent;

        color: $color-error;
        text-decoration: underline;
      }
    }

    .arrow::before {
      border-bottom-color: $color-error;
    }
  }
}

button {
  color: inherit;
}

.btn-primary,
.btn-default,
.btn-secondary {
  font-weight: 500;
}

.link-group {
  display: flex;
  padding: 4px 5px;

  border-radius: 12px;

  background: #f2f7f8;

  .btn {
    flex-grow: 1;
    width: 0;
    padding: 8px 0;

    box-shadow: none;

    color: #43959d;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    &.btn-primary {
      color: white;
    }
  }
}

.swipe-action {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  border: none;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 500;

  i {
    margin-top: 2px;

    font-size: 16px;
  }
}

.btn-filters {
  position: relative;
  margin-left: auto;
  padding: 8px;

  border: none;

  background: none;

  color: white;
  font-size: 14px;

  .indicator {
    display: inline-block;
    position: absolute;
    top: 20%;
    right: 20%;
    width: 5px;
    height: 5px;

    border-radius: 50%;

    background-color: $color-primary;
  }
}

.layout-mobile .mobile-hidden {
  display: none;
}
