@font-face {
  font-family: 'icons-font';
  src: url('~assets/fonts/icons/hosty.eot?t=1');
  src: url('~assets/fonts/icons/hosty.eot?t=1') format('embedded-opentype'),
    url('~assets/fonts/icons/hosty.ttf?t=1') format('truetype'),
    url('~assets/fonts/icons/hosty.woff?t=1') format('woff'),
    url('~assets/fonts/icons/hosty.svg?t=1') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='hosty-icon-'],
[class*=' hosty-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hosty-icon-close-menu:before { content: '\e800'; } /* '' */
.hosty-icon-integrations:before { content: '\e801'; } /* '' */
.hosty-icon-info-filled:before { content: '\e802'; } /* '' */
.hosty-icon-planet:before { content: '\e803'; } /* '' */
.hosty-icon-archive:before { content: '\e840'; } /* '' */
.hosty-icon-unarchive:before { content: '\e841'; } /* '' */
.hosty-icon-close-menu-1:before { content: '\e842'; } /* '' */
.hosty-icon-dashboard:before { content: '\e843'; } /* '' */
.hosty-icon-chat:before { content: '\e844'; } /* '' */
.hosty-icon-disconnect-acc:before { content: '\e847'; } /* '' */
.hosty-icon-check-ins-outs:before { content: '\e848'; } /* '' */
.hosty-icon-list:before { content: '\e84a'; } /* '' */
.hosty-icon-sort:before { content: '\e84b'; } /* '' */
.hosty-icon-line-chart:before { content: '\e84c'; } /* '' */
.hosty-icon-checkinout:before { content: '\e84d'; } /* '' */
.hosty-icon-arrow-double-left:before { content: '\e84e'; } /* '' */
.hosty-icon-drag:before { content: '\e84f'; } /* '' */
.hosty-icon-website:before { content: '\e850'; } /* '' */
.hosty-icon-dollar:before { content: '\e88a'; } /* '' */
.hosty-icon-arrow-right:before { content: '\e900'; } /* '' */
.hosty-icon-arrow-left:before { content: '\e901'; } /* '' */
.hosty-icon-no-card:before { content: '\e902'; } /* '' */
.hosty-icon-logo:before { content: '\e903'; } /* '' */
.hosty-icon-tick:before { content: '\e904'; } /* '' */
.hosty-icon-vrbo-logo:before { content: '\e905'; } /* '' */
.hosty-icon-airbnb-logo:before { content: '\e906'; } /* '' */
.hosty-icon-vrbo:before { content: '\e907'; } /* '' */
.hosty-icon-home:before { content: '\e908'; } /* '' */
.hosty-icon-burger:before { content: '\e909'; } /* '' */
.hosty-icon-user:before { content: '\e90a'; } /* '' */
.hosty-icon-upload:before { content: '\e90b'; } /* '' */
.hosty-icon-trash:before { content: '\e90d'; } /* '' */
.hosty-icon-minus:before { content: '\e90e'; } /* '' */
.hosty-icon-plus:before { content: '\e90f'; } /* '' */
.hosty-icon-chevron-down:before { content: '\e910'; } /* '' */
.hosty-icon-dots:before { content: '\e911'; } /* '' */
.hosty-icon-write:before { content: '\e912'; } /* '' */
.hosty-icon-uppercase:before { content: '\e913'; } /* '' */
.hosty-icon-cross:before { content: '\e914'; } /* '' */
.hosty-icon-attach:before { content: '\e915'; } /* '' */
.hosty-icon-doc:before { content: '\e916'; } /* '' */
.hosty-icon-message:before { content: '\e917'; } /* '' */
.hosty-icon-phone:before { content: '\e918'; } /* '' */
.hosty-icon-info:before { content: '\e919'; } /* '' */
.hosty-icon-search:before { content: '\e91a'; } /* '' */
.hosty-icon-graph:before { content: '\e91b'; } /* '' */
.hosty-icon-users:before { content: '\e91c'; } /* '' */
.hosty-icon-refresh:before { content: '\e91d'; } /* '' */
.hosty-icon-star:before { content: '\e91e'; } /* '' */
.hosty-icon-case:before { content: '\e91f'; } /* '' */
.hosty-icon-calendar:before { content: '\e920'; } /* '' */
.hosty-icon-message-outline:before { content: '\e921'; } /* '' */
.hosty-icon-send:before { content: '\e922'; } /* '' */
.hosty-icon-twice-tick:before { content: '\e923'; } /* '' */
.hosty-icon-settings:before { content: '\e924'; } /* '' */
.hosty-icon-stripe:before { content: '\e925'; } /* '' */
.hosty-icon-share:before { content: '\e926'; } /* '' */
.hosty-icon-sync:before { content: '\e927'; } /* '' */
.hosty-icon-save:before { content: '\e928'; } /* '' */
.hosty-icon-exit:before { content: '\e929'; } /* '' */
.hosty-icon-star-filled:before { content: '\e92a'; } /* '' */
.hosty-icon-flag-filled:before { content: '\e92b'; } /* '' */
.hosty-icon-flag-outline:before { content: '\e92c'; } /* '' */
.hosty-icon-task:before { content: '\e92d'; } /* '' */
.hosty-icon-download:before { content: '\e92e'; } /* '' */
.hosty-icon-booking-logo:before { content: '\e92f'; } /* '' */
.hosty-icon-copy:before { content: '\e930'; } /* '' */
.hosty-icon-dollar-1:before { content: '\e931'; } /* '' */
.hosty-icon-plus-circle:before { content: '\e932'; } /* '' */
.hosty-icon-warning:before { content: '\e933'; } /* '' */
.hosty-icon-channels:before { content: '\e934'; } /* '' */
.hosty-icon-hidden:before { content: '\e935'; } /* '' */
.hosty-icon-visible:before { content: '\e936'; } /* '' */
.hosty-icon-homeaway:before { content: '\e937'; } /* '' */
.hosty-icon-glyph:before { content: '\e938'; } /* '' */
.hosty-icon-glyph-1:before { content: '\e939'; } /* '' */
.hosty-icon-hosty:before { content: '\e93a'; } /* '' */
.hosty-icon-preferences:before { content: '\e93b'; } /* '' */
.hosty-icon-help:before { content: '\e93c'; } /* '' */
.hosty-icon-disconnect:before { content: '\e93d'; } /* '' */
.hosty-icon-arrow-top:before { content: '\e93e'; } /* '' */
.hosty-icon-rounded-arrow:before { content: '\e93f'; } /* '' */
