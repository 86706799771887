.font-size-24 {
  font-size: 24px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-40 {
  font-size: 40px;
}

.line-height-48 {
  line-height: 48px;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-10 {
  font-size: 10px;
}

.letter-s-1 {
  letter-spacing: 1px;
}

.text-muted {
  color: $color-muted-gray !important;
}

.text-danger {
  color: $color-error;
}

.color-black {
  color: $color-black;
}

.color-dark {
  color: $color-dark;
}

.color-danger {
  color: $color-error;
}

a.color-black {
  &:hover {
    color: $color-black;
  }
}

.color-primary {
  color: $color-primary;
}

.color-white {
  color: $color-white;
}

.line-height-25 {
  line-height: 25px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-38 {
  line-height: 38px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-18 {
  line-height: 18px;
}

.font-weight-bold {
  font-weight: bold;
}

.ui-list {
  padding: 0;

  list-style: none;
}

span.before-dot {
  position: relative;
  z-index: 0;
  &::before {
    content: '';

    display: block;
    position: absolute;
    top: 7px;
    left: -12px;
    width: 5px;
    height: 5px;

    border-radius: 50%;

    background: $color-primary;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.color-vrbo {
  color: $color-vrbo;
}

.cut-text {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-right {
  text-align: right;
}
