.radius-4 {
  border-radius: 4px;
}

.m-w-200 {
  min-width: 200px;
}

.w-80 {
  width: 80px;
}

.w-230 {
  width: 230px;
}

.w-240 {
  width: 240px;
}

.w-265 {
  width: 265px;
}

.w-290 {
  width: 290px;
}

.w-460 {
  width: 460px;
}

.border-none {
  border: none;
}

.pac-container {
  border-radius: 10px;

  .pac-item {
    line-height: 35px;
    &:hover {
      background-color: transparent;
      span {
        color: $color-primary;
      }
    }
    .pac-icon {
      margin: 5px 0 6px 6px;

      background: url('~assets/images/location.svg') no-repeat center;
      background-size: 12px;

      vertical-align: middle;
    }
    span {
      color: black;
      font-size: 14px;
      font-weight: normal;
    }
    .pac-item-query {
      margin-left: 10px;
    }
  }

  &::after {
    display: none;
  }
}

span.hosty-icon-warning {
  min-width: 16px;

  border-radius: 20px;

  background: $color-error;

  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.top-panel {
  padding: 8px;

  background: $color-white;
  box-shadow: 0 2px 3px rgb(132 145 146 / 0.1);

  &__top {
    margin: 0 -8px;
    padding: 0 8px 8px;

    border-bottom: 1px solid $color-input-gray;
  }

  &__bottom {
    padding: 12px 8px;
  }

  hosty-app-back-button {
    position: relative;
    &::after {
      content: '';

      display: block;
      position: absolute;
      top: -20px;
      right: -12px;
      width: 1px;
      height: 60px;

      background: $color-input-gray;
    }
  }

  .layout-mobile & {
    padding: 8px 12px 12px;

    border-bottom: 2px solid #e6e6eb;
    border-radius: 0 0 24px 24px;
  }
}

.upload-avatar-circle {
  position: relative;
  width: 122px;
  height: 122px;
  overflow: hidden;

  border-radius: 50%;

  background: #ffffff;

  cursor: pointer;

  .upload-avatar-circle-img {
    position: relative;
    z-index: 0;
  }

  p {
    line-height: 160px;
  }

  &:hover {
    .hosty-icon-upload {
      bottom: 10px;
    }

    &::after {
      bottom: -66px;
    }
  }

  .hosty-icon-upload {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 6px;
    left: 0;
    margin: auto;

    transition: all 0.1s linear;

    color: $color-primary;
    font-size: 16px;
  }

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -74px;
    left: 0;
    width: 100px;
    height: 100px;
    margin: auto;

    transition: all 0.1s linear;

    border-radius: 50%;

    opacity: 0.1;
    background: #43959d;
  }

  &.file-uploaded {
    .hosty-icon-upload {
      color: $color-white;
    }
    &::after {
      opacity: 1;
    }
  }
}

.small-container {
  max-width: 85%;
}

.w-180 {
  min-width: 180px;
}

.w-120 {
  width: 120px;
}

.w-400 {
  width: 400px;
}

.content-right-sidebar {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .content-wrap {
    flex: 1;
    padding-right: 16px;
    overflow: hidden;

    .content {
      box-sizing: content-box; /* So the width will be 100% + 17px */
      max-height: 100%;
      padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.right-sidebar {
  position: relative;
  width: 242px;
  height: 100%;

  &-inner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 242px;
    height: 100%;
    padding: 8px 8px 56px;
    overflow: auto;

    background: $color-white;
  }

  &-item {
    padding: 8px 0;

    &:last-child {
      padding-bottom: 0;
    }

    &:where(:not(:last-child)) {
      border-bottom: 1px solid $color-input-gray;
    }
  }

  &-item-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 25px 0 0 0;
  }

  &-foot {
    display: flex;
    position: fixed;
    z-index: 10;
    right: 8px;
    bottom: 8px;
    align-items: center;
    width: inherit;
    padding: 8px;

    transition: width 0.3s, opacity 0.1s;

    border-bottom-right-radius: 16px;

    background-color: $color-white;

    white-space: nowrap;
  }

  .layout-mobile & {
    &-inner {
      padding-top: var(--safe-top);
      padding-bottom: calc(40px + var(--safe-bottom, 8px));
    }
  }
}

.sidebar-picker {
  &__list {
    max-height: 330px;
    overflow-y: auto;
  }
  &__btn-trash {
    display: none;

    border: none;

    background-color: #fff;

    color: #849192;
  }
  &__name {
    word-break: break-all;
  }
}

.status-label {
  padding: 0 18px;

  border: 1px solid #43959d;
  border-radius: 4px;

  background: $color-primary-light;

  color: $color-primary;
  font-size: 10px;
  line-height: 22px;
  text-transform: uppercase;
}

.m-l-21 {
  margin-left: 21px;
}

.w-40 {
  width: 40px;
  min-width: 40px;
}

.w-300 {
  width: 300px;
}

.w-320 {
  width: 320px;
}

.w-160 {
  width: 160px;
}

.w-550 {
  width: 550px;
}

.w-500 {
  width: 500px;
}

.w-520 {
  width: 520px;
}

.w-380 {
  width: 380px;
}

.w-600 {
  width: 600px;
}

.w-50 {
  width: 50%;
}

agm-map {
  height: 240px;
}

.listings-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  & > * {
    width: calc(33.33% - 24px);
    margin: 12px;
  }
}

.items-list {
  &__item {
    margin-bottom: 4px;
    padding: 8px;

    border-radius: 8px;

    background: $color-white;
  }
}

.filter-sidebar-item {
  padding: 8px 0;

  border-bottom: 1px solid $color-input-gray;
}

.status {
  font-size: 8px;
  line-height: 14px;
  text-transform: uppercase;
}

.not-selected-chat-img {
  width: 80px;
  height: 76px;

  background: url('~assets/images/inbox-not-selected.png') no-repeat center;
  background-size: contain;
}

.hosty-tooltip {
  position: relative;
  top: -14px;
  padding: 12px;

  border-radius: 8px;

  background: $color-white;
  box-shadow: 0 8px 12px rgb(230 230 235 / 0.5);

  &::after {
    content: '';

    display: block;
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;

    border-top: 8px solid $color-white;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}

.ios-toggler {
  position: relative;
  width: 40px;
  height: 24px;

  transition: all 0.2s ease-in-out;

  border-radius: 50px;

  background: #e6e6eb;

  & > span {
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;

    border-radius: 50%;

    background: $color-white;
    box-shadow: 0 2px 3px rgb(13 49 52 / 0.1);

    cursor: pointer;
  }

  &--selected {
    background: $color-primary;

    & > span {
      left: calc(100% - 22px);
    }
  }
}

.circle-icon {
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;
  width: 16px;
  height: 16px;

  border: 2px solid $color-white;
  border-radius: 50%;

  background: $color-airbnb;

  color: $color-white;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
}

.is-airbnb {
  @extend .hosty-icon-airbnb-logo;
  @extend .circle-icon;
  z-index: 1;

  font-family: 'icons-font';

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px !important;
    line-height: 24px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;

    font-size: 16px !important;
    line-height: 32px;
  }

  &.size-20 {
    width: 20px;
    height: 20px;

    font-size: 10px !important;
    line-height: 20px;
  }
}

.btn-circle.size-32 {
  width: 32px;
  height: 32px;

  line-height: 33px;
}

.is-vrbo {
  @extend .hosty-icon-vrbo-logo;
  @extend .circle-icon;
  z-index: 0;

  background: $color-vrbo !important;

  font-family: 'icons-font';
  font-size: 7px !important;

  &.size-20 {
    width: 20px;
    height: 20px;

    font-size: 10px !important;
    line-height: 20px;
  }

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px !important;
    line-height: 24px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;

    font-size: 18px !important;
    line-height: 32px;
  }
}

.is-homeaway {
  @extend .hosty-icon-homeaway;
  @extend .circle-icon;
  z-index: 0;

  background: $color-homeaway !important;

  font-family: 'icons-font';
  font-size: 7px !important;

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px !important;
    line-height: 24px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;

    font-size: 18px !important;
    line-height: 32px;
  }
}

.is-booking {
  @extend .hosty-icon-booking-logo;
  @extend .circle-icon;
  z-index: 0;

  background: $color-booking !important;

  font-family: 'icons-font';
  font-size: 7px !important;

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px !important;
    line-height: 24px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;

    font-size: 18px !important;
    line-height: 32px;
  }
}

.is-hosty {
  @extend .hosty-icon-hosty;
  @extend .circle-icon;
  z-index: 0;

  background: $color-primary !important;

  color: $color-white;
  font-family: 'icons-font';
  font-size: 7px !important;

  &.size-20 {
    width: 20px;
    height: 20px;

    font-size: 10px !important;
    line-height: 20px;
  }

  &.size-24 {
    width: 24px;
    height: 24px;

    font-size: 12px !important;
    line-height: 24px;
  }

  &.size-32 {
    width: 32px;
    height: 32px;

    font-size: 18px !important;
    line-height: 32px;
  }
}

.is-airbnb + .is-vrbo:not(.m-l-10) {
  margin-left: -6px;
}

.hosty-box {
  padding: 10px 12px;

  border-radius: 8px;

  background: $color-primary-light;
}

.hosty-dp-toggler {
  top: 9px;
  right: 8px;
}

.dropdown {
  --border-color: #e6e6eb;
  --arrow-color: #e6e6eb;

  min-width: 190px;
  margin-top: 12px;
  padding: 12px 16px;

  border: 1px solid var(--border-color);
  border-radius: 8px;

  background: $color-white;
  box-shadow: 0 8px 12px rgb(230 230 235 / 0.5);

  &.datepicker--dropdown {
    box-shadow: 0 4px 40px #dadada;
  }

  &.dropdown-payout {
    min-width: 300px;
    margin-right: -12px;
  }

  &.dropdown-rating {
    min-width: 275px;
    margin-right: -12px;
  }

  &.dropdown--left {
    &::before {
      right: auto;
      left: 14px;
    }
    &::after {
      right: auto;
      left: 12px;
    }
  }

  &.dropdown--bottom {
    margin-bottom: 12px;

    &::before {
      top: 100%;
      right: auto;
      left: 14px;

      transform: rotate(180deg);
    }
    &::after {
      top: 100%;
      right: auto;
      left: 12px;

      transform: rotate(180deg);
    }
  }

  &::before {
    content: '';

    position: absolute;
    z-index: 1;
    top: -8px;
    right: 14px;
    width: 0;
    height: 0;

    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent $color-white transparent;
  }

  &::after {
    content: '';

    position: absolute;
    z-index: 0;
    top: -10px;
    right: 12px;
    width: 0;
    height: 0;

    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent var(--arrow-color) transparent;
  }

  &.dropdown--bill {
    top: 46px;
    left: 60px;
    width: 187px;
    margin: 0;

    background-color: #ff6361;
    &::before {
      top: 117px;
      right: auto;
      left: -12px;

      transform: rotate(270deg);

      border-color: transparent transparent #43959d transparent;
    }
    &::after {
      display: none;
    }
  }

  &__list {
    &__item {
      color: $color-black;
      font-size: 14px;
      line-height: 13px;

      cursor: pointer;

      &:not(:first-child) {
        padding-top: 7px;
      }
      &:not(:last-child) {
        padding-bottom: 7px;
      }

      &__icon {
        color: $color-muted-gray;
        font-size: 16px;
      }
    }
  }

  .layout-mobile & {
    &::before,
    &::after {
      content: none;
    }
  }
}

.dropdown-priority {
  position: relative;
  z-index: 1;
  left: 50px;
  min-width: 250px;
  margin-top: 12px;
  padding: 12px 16px;

  border: 1px solid $color-white;
  border-radius: 8px;

  background: $color-white;

  color: $color-orange;

  &.datepicker--dropdown {
    box-shadow: 0 4px 40px #dadada;
  }

  &.dropdown-rating {
    min-width: 90px;
  }

  &.dropdown-priority--left {
    &::before {
      right: auto;
      left: 14px;
    }
    &::after {
      right: auto;
      left: 12px;
    }
  }

  &.dropdown--bottom {
    margin-bottom: 12px;

    &::before {
      top: 69px;
      right: auto;
      left: 14px;

      transform: rotate(180deg);
    }
    &::after {
      top: 70px;
      right: auto;
      left: 12px;

      transform: rotate(180deg);
    }
  }

  &::before {
    content: '';

    position: absolute;
    z-index: 2;
    top: -8px;
    right: 60px;
    width: 0;
    height: 0;

    border-width: 0 8px 8px 8px;
    border-style: solid;
    border-color: transparent transparent $color-white transparent;
  }

  &__list {
    &__item {
      color: $color-orange;
      font-size: 14px;
      line-height: 13px;

      cursor: pointer;

      &:not(:first-child) {
        padding-top: 7px;
      }
      &:not(:last-child) {
        padding-bottom: 7px;
      }
    }
  }
}

.back-btn {
  width: 20px;
  height: 20px;

  border: none;
  border-radius: 50%;

  background: $color-primary-light;

  color: $color-primary;
  font-size: 8px;
  line-height: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.empty-hide {
  &:empty {
    display: none;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

cdk-virtual-scroll-viewport.hidden-scroll-thumb {
  //.cdk-virtual-scroll-content-wrapper {
  //  overflow: scroll;
  //  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  //  box-sizing: content-box; /* So the width will be 100% + 17px */
  //}
}

.mw-100 {
  max-width: 100%;
}

.content-box {
  box-sizing: content-box;
}

.command-combines {
  padding: 4px;

  border-radius: 4px;

  background: #f2f7f8;

  color: #43959d;
}

.btn {
  &.size-40 {
    width: 40px;
    height: 40px;
    padding: 0;

    line-height: 43px;
  }
}

.border-bottom-gray {
  border-bottom: 1px solid $color-input-gray;
}

.border-left-gray {
  border-left: 1px solid $color-input-gray;
}

.border-right-gray {
  border-right: 1px solid $color-input-gray;
}

.border-radius-8 {
  overflow: hidden;

  border-radius: 8px;
}

cdk-virtual-scroll-viewport.cdk-virtual-scroll-viewport.mw-100 {
  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}

cdk-virtual-scroll-viewport.cdk-virtual-scroll-viewport.mh-100 {
  .cdk-virtual-scroll-content-wrapper {
    height: 100%;
  }
}

.h-40 {
  height: 40px;
}

.h-140 {
  height: 140px;
}

.hidden-scrollbar {
  width: 100%;
  height: 100%;
  overflow: hidden;

  cdk-virtual-scroll-viewport {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    padding-bottom: 16px;
  }
}

.flex-basis-100 {
  flex-basis: 100%;
}

.bg-primary-light {
  background: $color-primary-light;
}

.progress-bar {
  position: relative;
  width: 100%;
  min-width: 120px;
  height: 8px;

  border-radius: 8px;

  background: $color-light-primary;

  & > span {
    height: 100%;

    transition: all 0.3s ease-in-out;

    border-radius: 8px 0 0 8px;

    background: $color-primary;
  }
}

.no-bottom-border {
  border-bottom-color: transparent !important;
}

textarea.no-resize {
  resize: none;
}

.message-form-control {
  height: 40px;
  min-height: 40px !important;

  border-radius: 20px;

  color: $color-black;
  font-size: 14px;
  line-height: 18px;
}

.transition-03s {
  transition: all 0.2s ease-in-out;
}

.tabs {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  &__item {
    position: relative;
    padding: 8px 16px 10px 16px;

    border-radius: 8px 8px 0 0;

    color: $color-muted-gray;
    font-size: 14px;
    line-height: 18px;

    cursor: pointer;
    &:where(:not(:first-child)) {
      margin-left: 16px;
    }
    &::after {
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;

      border-radius: 1px 1px 0 0;

      background: $color-primary;
    }

    &.active {
      background: $color-primary-light;

      color: $color-primary;

      &::after {
        height: 2px;
      }
    }
  }
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.hosty-tool-title {
  display: inline-block;
  position: relative;
}

.hosty-tool-title[title]:hover::after {
  content: attr(title);

  position: absolute;
  top: -100%;
  left: -100%;
  min-width: 116px;

  background: rgb(4 16 17 / 0.85);

  color: $color-white;
  font-size: 14px;
  line-height: 18px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.loading-overlay {
  display: flex;
  position: absolute;
  z-index: 91;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background: rgb(255 255 255 / 0.8);
}

.block-with-panel {
  &__top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__bottom {
    max-height: calc(100% - 45px);

    border-right: 1px solid #e6e6eb;
    border-bottom: 1px solid #e6e6eb;
    border-left: 1px solid #e6e6eb;
    border-radius: 0 0 8px 8px;
  }
}

.dot {
  display: inline-block;
  width: 3px;
  height: 3px;

  border-radius: 50%;

  background: $color-primary;
}

.uploaded-file {
  position: relative;
  max-width: 120px;
  padding: 8px;

  border-radius: 8px;

  background: $color-white;

  .btn-circle {
    position: absolute;
    top: -6px;
    right: -6px;
  }

  &__name {
    color: $color-primary;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    word-break: break-all;
  }
}

.pdf-file-icon {
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;

  background: url('~assets/images/pdf-icon.png') no-repeat center;
  background-size: contain;
}

.map-container {
  width: 100% !important;
  height: 100% !important;
}

.alert {
  &.alert-danger {
    border: none;
    border-radius: 8px;

    background: rgb(255 99 97 / 0.1);

    color: #ff6361;
    font-size: 14px;
    line-height: 18px;
  }
}

.warning-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-right: 6px;

  border-radius: 50%;

  background: #ff6361;

  color: #fff;
  font-size: 8px;
  line-height: 14px;
  text-align: center;
}
