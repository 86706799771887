@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  src: url('~assets/fonts/Rubik/Rubik-Regular.eot');
  src: url('~assets/fonts/Rubik/Rubik-Regular.eot?') format('embedded-opentype'),
    url('~assets/fonts/Rubik/Rubik-Regular.woff2') format('woff2'),
    url('~assets/fonts/Rubik/Rubik-Regular.woff') format('woff'),
    url('~assets/fonts/Rubik/Rubik-Regular.ttf') format('truetype'),
    url('~assets/fonts/Rubik/Rubik-Regular.svg') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/Rubik/Rubik-Medium.eot');
  src: url('~assets/fonts/Rubik/Rubik-Medium.eot?') format('embedded-opentype'),
    url('~assets/fonts/Rubik/Rubik-Medium.woff2') format('woff2'),
    url('~assets/fonts/Rubik/Rubik-Medium.woff') format('woff'),
    url('~assets/fonts/Rubik/Rubik-Medium.ttf') format('truetype'),
    url('~assets/fonts/Rubik/Rubik-Medium.svg') format('svg');
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('~assets/fonts/Rubik/Rubik-Light.eot');
  src: url('~assets/fonts/Rubik/Rubik-Light.eot?#iefix') format('embedded-opentype'),
    url('~assets/fonts/Rubik/Rubik-Light.woff2') format('woff2'),
    url('~assets/fonts/Rubik/Rubik-Light.woff') format('woff'),
    url('~assets/fonts/Rubik/Rubik-Light.ttf') format('truetype'),
    url('~assets/fonts/Rubik/Rubik-Light.svg') format('svg');
  font-display: swap;
}
