.ngx-toastr.hosty-toast {
  width: auto;
  min-width: 220px;
  padding: 12px 16px;

  border-radius: 8px;

  box-shadow: none;

  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;

  &.hosty-toast-success {
    background: $color-primary;
    box-shadow: 0 12px 40px rgb(67 149 157 / 0.4);

    color: $color-white;
  }

  &.hosty-toast-warning {
    background: $color-orange;
    box-shadow: 0 12px 40px rgb(242 165 83 / 0.4);

    color: $color-white;
  }

  &.hosty-toast-error {
    background: $color-error;
    box-shadow: 0 12px 40px rgb(255 121 119 / 0.4);

    color: $color-white;
  }
}

.toast-bottom-center {
  bottom: 5% !important;
}

.toast-bottom-right {
  right: 5% !important;
  bottom: 5% !important;
}
