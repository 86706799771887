.modal-overlay {
  background: rgb(0 0 0 / 0.8);
}

.modal-panel,
.modal-panel-center,
.modal-confirm {
  border-radius: 8px;

  background: $color-white;
}

.layout-mobile {
  .modal-panel {
    align-self: flex-end;
    width: 100%;
    max-height: 90%;

    border-radius: 8px 8px 0 0;

    > * {
      padding-bottom: var(--safe-bottom, 0);
    }
  }
}

.bottom-sheet {
  position: fixed;
  z-index: 90;
  right: 0;
  bottom: calc(68px + var(--safe-bottom, 0px));
  left: 0;
  padding: 12px;
  padding-bottom: calc(8px + var(--safe-bottom, 0px));

  border-radius: 24px 24px 0 0;

  background: #ffffff;
  box-shadow: 0 -8px 32px rgb(13 49 52 / 0.05);
}
