@import '../variables';

.control-label {
  color: $color-muted-gray;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 21px;

  &.color-white {
    color: #e6e6eb !important;
  }
}

.form-group {
  margin-bottom: 20px;

  &.float-error {
    position: relative;
    hosty-app-control-error:empty {
      display: none;
    }
    hosty-app-control-error {
      display: block;
      position: absolute;
      z-index: 1;
      top: calc(100% + 4px);
      right: 0;
      height: 30px;
      padding: 6px;

      border-radius: 4px;

      background: $color-error;

      &::before {
        content: '';

        position: absolute;
        top: -7px;
        right: 4px;
        width: 0;
        height: 0;

        border-right: 7px solid transparent;
        border-bottom: 7px solid $color-error;
        border-left: 7px solid transparent;
        border-radius: 2px;
      }
    }
  }
}

.form-control {
  padding: 9px 12px;

  border: 1px solid $color-input-gray;
  border-radius: 8px;

  color: $color-black;
  font-size: 14px;
  font-weight: normal;

  &.cdk-textarea-autosize-measuring {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  &[readonly] {
    background: $color-white;

    color: $color-muted-gray;

    &.no-readonly-effect {
      color: $color-black;
    }
  }

  &:disabled {
    background-color: $bg-disabled;
  }

  &::placeholder {
    color: $color-muted-gray;
    font-weight: normal;
  }

  &:hover {
    border-color: $color-medium-gray;
  }

  &:focus {
    border-color: $color-medium-gray;

    box-shadow: none;
  }

  &.ng-invalid.ng-touched {
    border-color: $color-error !important;

    color: $color-error !important;
  }

  &.form-control--casper {
    padding: 0;

    border-radius: 0;
    border-color: transparent;

    background: transparent;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.form-group {
  .error-text {
    display: block;
    height: 0;
  }
}

.hosty-select {
  --height: 36px;

  &.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: $color-error;
    }
  }

  &.ng-select-single,
  &.ng-select-multiple {
    .ng-select-container {
      height: var(--height);
      background: var(--select-bg, #fff);
      border: var(--select-border, 1px solid #e6e6eb);
      min-height: var(--height);

      .ng-value-container {
        .ng-placeholder {
          padding: 0 10px;
        }

        .ng-input {
          top: 0;
          height: 100%;

          input {
            height: 100%;
          }
        }
      }
    }
  }

  .ng-placeholder {
    font-size: 14px;
  }
}

ng-select.hosty-select__full-h {
  .ng-select-container {
    height: 100% !important;
  }
}

.hosty-select-group {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  border: 1px solid #e6e6eb;
  border-radius: 8px;

  & > ng-select.hosty-select:first-child {
    .ng-select-container {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 8px;
    }
  }
  & > ng-select.hosty-select:last-child {
    .ng-select-container {
      border-top-left-radius: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 0;
    }
  }

  & > ng-select.hosty-select {
    flex-basis: 0;
    flex-grow: 1;
    .ng-select-container,
    .ng-select-container.ng-select-opened,
    .ng-select-container.ng-select-focused {
      width: 100% !important;
      min-width: 100%;

      border-top: none !important;
      border-bottom: none !important;
      border-left: none;
      border-radius: 0;

      box-shadow: none !important;
    }
  }
}

.create-tags-list {
  height: 32px;
  margin-right: 10px;
  span {
    margin: 10px 0 5px 0;
    padding: 4px 10px 4px 4px;

    border-radius: 20px;

    background: #ffffff;

    color: #041011;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    .btn-tag {
      border: 1px solid #f2f7f8;
      border-radius: 12px;

      background: #f2f7f8;

      color: #43959d;
    }
  }
}

ng-select.hosty-select {
  &.roles-picker {
    &.ng-select-disabled {
      .ng-select-container {
        background-color: white;
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }

    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          color: $color-muted-gray;
        }
      }
    }
  }

  &.payment-status {
    &.ng-select-disabled {
      .ng-select-container {
        background-color: transparent;
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  &.wrap-options-text .ng-option {
    white-space: pre-wrap;
  }

  &.hosty-select-multiline-option {
    .ng-option {
      white-space: normal;
    }
  }

  &.hosty-select--h40 {
    .unpaid {
      display: inline-block;
      padding: 5px 16px;

      border-radius: 4px;

      background: $color-error-light;

      color: $color-error;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
    .paid {
      display: inline-block;
      padding: 5px 16px;

      border-radius: 4px;

      background: $color-primary-light;

      color: $color-primary;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }

    .ng-select-container {
      height: 40px;
    }
  }
  &.hosty-select--h-auto {
    .ng-value-container,
    .ng-select-container {
      height: auto;
    }
  }

  &.hosty-select--listing {
    .ng-value-container {
      .ng-value {
        padding: 10px 0;
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected::before {
      display: none;
    }
  }

  &.hosty-select-code-phone {
    &.disabled {
      .ng-select-container {
        background-color: transparent;
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding: 10px 0 10px 10px;

      font-size: 14px;

      &.ng-option-selected {
        color: $color-primary;
        .chosen {
          @extend .hosty-icon-tick;
          width: 10px;
          height: 10px;
          margin-left: 8px;

          color: $color-primary;
          font-family: 'icons-font';
          font-size: 10px;
        }
        &::before {
          display: none;
        }
      }
    }
    .ng-select-container {
      height: 41px;
    }
  }

  &.hosty-select--no-tick {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected::before {
      display: none;
    }

    &.hosty-select--calendar {
      .ng-value-container {
        .ng-value {
          .ng-value-label {
            font-weight: normal !important;
          }
        }
      }
    }
  }

  .ng-select-container {
    height: 40px;

    border: 1px solid #e6e6eb;
    border-radius: 8px;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container {
      padding-right: var(--select-value-padding-right, 8px);
      padding-left: var(--select-value-padding-left, 12px);

      .ng-input {
        font-size: 14px;
        line-height: 27px;
      }
    }

    .ng-arrow-wrapper {
      width: var(--select-arrow-size, 24px);
      height: var(--select-arrow-size, 24px);
      margin-right: var(--select-arrow-margin-right, 12px);
      padding: 0;

      transition: transform 0.2s ease-in-out;

      border-radius: 50%;

      background: $color-light-primary;

      line-height: var(--select-arrow-size, 24px);

      .ng-arrow {
        display: block;
        top: 0;
        width: auto;
        height: auto;

        border: none;

        color: $color-primary;

        &::before {
          content: '\e910';

          display: block;

          font-family: 'icons-font';
          font-size: 8px;
        }
      }
    }
  }

  .ng-value {
    color: $color-black;
    font-size: var(--select-font-size, 14px);
  }
  &.ng-select-tag {
    z-index: 5;
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      :hover {
        .selected-text {
          color: $color-primary;
        }
        .sidebar-picker__btn-trash {
          display: flex;
          :hover {
            color: $color-error;
          }
        }
      }
      .chosen {
        width: 16px;
        height: 16px;
        margin: 3px 3px 0 0;

        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        border-color: #e3e2e5;

        color: transparent;
        line-height: 12px;
        text-align: center;
        span {
          font-size: 10px;
        }
      }

      &.ng-option-selected {
        color: $color-primary;
        .chosen {
          border-color: $color-primary;

          background: $color-primary;

          color: #ffffff;
        }
        &::before {
          display: none;
        }
      }
    }
    //.ng-select-container {
    //  height: 41px ;
    //}
    &.ng-select-focused {
      .place {
        display: none;
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border: var(--select-opened-border, 1px solid #e6e6eb);
      border-radius: 8px !important;

      .ng-arrow-wrapper {
        transform: rotate(180deg);

        background: $color-primary;
        .ng-arrow {
          color: $color-white;
        }
      }
    }
  }

  &.ng-select-arrow-hide {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border: var(--select-focused-border, 1px solid #e6e6eb) !important;

      box-shadow: none !important;
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      background-color: #f8fbfc;
    }

    .ng-arrow-wrapper {
      background-color: #f2f7f8;

      .ng-arrow {
        color: #aeb5b6;
      }
    }
  }

  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: $color-error;

      .ng-value {
        color: $color-error;
      }
    }
  }
}

ng-select.hosty-select.group-select {
  &.ng-select-multiple {
    --height: auto;

    .ng-select-container {
      min-height: 42px;

      &.ng-has-value {
        align-items: flex-start;
        .ng-arrow-wrapper {
          margin-top: 8px;
        }
      }
    }

    .ng-value-container {
      flex-direction: column;
      align-items: flex-start;

      .ng-value {
        display: flex;
        flex-direction: row-reverse;
        background-color: $color-primary-light;
        border-radius: 4px;

        .ng-value-icon.left {
          color: $color-primary;
          font-size: 24px;
          border-right: none;
          border-left: 1px solid #fff;
          display: flex;
          height: 19px;
          align-items: center;
          font-weight: 300;
          margin: 4px 0;

          &:hover {
            background-color: transparent;
          }
        }

        .ng-value-label {
          padding: 3px 8px;
          color: $color-dark;
          font-size: 14px;
        }
      }

      .ng-placeholder {
        padding-top: 5px;
      }
    }

    .ng-input {
      display: none;
    }

    &.ng-select-disabled {
      .ng-value-icon.left {
        display: none !important;
      }
    }
  }
}

.hosty-select.ng-dropdown-panel {
  &.wrap-options-text {
    .ng-dropdown-panel-items {
      .ng-option {
        white-space: normal;
      }
    }
  }
}

.hosty-select.ng-dropdown-panel,
.hosty-select .ng-dropdown-panel {
  min-width: var(--select-dropdown-panel-min-width, 80px);
  margin-top: 4px;
  overflow: hidden;

  border: 1px solid #e6e6eb;
  border-radius: 8px !important;

  background: #f5f9fa;
  box-shadow: 0 8px 12px rgb(230 230 235 / 0.5);

  .ng-dropdown-header {
    padding: 0;

    border: none;

    background-color: white;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      display: flex;
      align-items: center;
      padding: 6px 16px;

      &.ng-option-disabled {
        opacity: 0.5;

        cursor: not-allowed;
      }

      .ng-option-label {
        color: $color-black;
        font-size: 14px;
      }

      &.ng-option-marked {
        background: #ffffff !important;
        box-shadow: none !important;
      }

      &.ng-option-selected {
        background: #ffffff !important;

        &::before {
          @extend .hosty-icon-tick;
          margin-right: 8px;

          color: $color-primary;
          font-family: 'icons-font';
          font-size: 9px;
        }

        .ng-option-label {
          color: $color-primary;
          font-weight: normal;
        }
      }
    }
  }

  .scroll-host {
    max-height: 200px;
  }
}

ng-select.hosty-select.hosty-select--bold {
  .ng-select-container {
    .ng-value {
      margin-right: 12px;

      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

ng-select.hosty-select.hosty-select--sidebar {
  .ng-select-container {
    border-color: transparent;
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      border-color: transparent !important;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: auto;
    }
    .ng-value-container {
      padding-left: 5px;
    }
    .ng-value {
      background-color: transparent !important;
      .ng-value-icon {
        display: none !important;
      }
    }
  }
}

.ui-checkbox {
  &.squared-checkbox {
    label,
    .label {
      width: 16px;
      height: 16px;

      border-radius: 4px;

      line-height: 14px;

      &::before {
        font-size: 8px;
      }
    }
  }
  input {
    display: none;
  }

  label,
  .label {
    display: inline-block;
    width: 24px;
    height: 24px;

    border: 1px solid $color-input-gray;
    border-radius: 50%;

    background: $color-white;

    line-height: 20px;
    text-align: center;

    &::before {
      @extend .hosty-icon-tick;
      transition: all 0.2s linear;

      color: transparent;
      font-family: 'icons-font';
      font-size: 0;
      text-align: center;
    }
  }

  input:checked {
    + label,
    + .label {
      border-color: $color-primary;

      background-color: $color-primary;

      color: $color-white;

      &::before {
        color: $color-white;
        font-size: 9px;
      }
    }
  }

  input:disabled {
    + label,
    + .label {
      background-color: $bg-disabled;
    }

    &:checked {
      + label,
      + .label {
        border-color: #aeb5b6;

        background-color: #aeb5b6;
      }
    }
  }
}

.input-group-text {
  height: 41px;

  border-radius: 0;
  border-left-color: transparent;

  background: $color-white;
}

.input-group {
  .form-control {
    margin-bottom: 0;
  }
  .input-group-prepend {
    .input-group-text {
      border-radius: 8px 0 0 8px;
      border-color: $color-input-gray;
      border-right-color: transparent;

      color: $color-black;
      font-size: 14px;
    }
  }

  .input-group-prepend + .form-control {
    border-left-color: transparent;
  }

  .form-control:focus + .input-group-append .input-group-text {
    border-top-color: $color-input-gray;
    border-right-color: $color-input-gray;
    border-bottom-color: $color-input-gray;
  }

  .form-control.ng-invalid.ng-touched + .input-group-append .input-group-text {
    border-top-color: $color-error;
    border-right-color: $color-error;
    border-bottom-color: $color-error;

    color: $color-error;
  }

  .input-group-append .input-group-text {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    border-radius: 0 6px 6px 0;
    border-color: $color-input-gray;
    border-left-color: transparent;

    color: $color-muted-gray;
    font-size: 14px;
  }

  .form-control:disabled + .input-group-append .input-group-text {
    background-color: $bg-disabled;
  }

  &.input-group--with-append {
    .form-control {
      border-right-color: transparent;
    }

    .form-control:focus + .input-group-append .input-group-text {
      border-top-color: $color-medium-gray;
      border-right-color: $color-medium-gray;
      border-bottom-color: $color-medium-gray;

      color: $color-medium-gray;
    }
  }

  &.input-group--select {
    .prepand {
      width: 80px;
      padding-left: 12px;

      border: 1px solid $color-input-gray;
      border-right: none;
      border-radius: 6px 0 0 6px;

      background: $color-white;

      font-size: 14px;
      line-height: 39px;
    }
    .prepand::after {
      content: '';

      display: block;
      position: absolute;
      left: 33%;
      width: 1px;
      height: 25px;

      transform: translateY(-125%);

      background: $color-input-gray;
    }

    ng-select.hosty-select--input-group {
      position: relative;
      .ng-select-container {
        width: 80px;
        height: 41px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-color: transparent;
      }

      &.ng-select-focused {
        .ng-select-container {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-right-color: transparent !important;
        }
      }

      &::after {
        content: '';

        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 25px;
        margin-top: -12.5px;

        background: $color-input-gray;
      }
    }

    .form-control {
      width: 50px;
      padding-right: 0;
      padding-left: 15px;

      border-right-color: transparent;
      border-left-color: transparent;
    }

    &.input-group--select--append {
      .form-control {
        border-left-color: #e6e6eb;
      }

      ng-select.hosty-select--input-group {
        .ng-select-container {
          border-radius: 0 8px 8px 0;
          border-right-color: #e6e6eb;
          border-left-color: transparent;
        }
        &.ng-select-focused {
          .ng-select-container {
            border-radius: 0 8px 8px 0 !important;
            border-right-color: #e6e6eb !important;
            border-left-color: transparent !important;
          }
        }
      }
    }
  }
}

ng-select.hosty-select.hosty-select--month {
  .ng-value-label {
    margin-right: 6px;

    font-size: 14px;
    font-weight: normal;
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      background: transparent;
      .ng-arrow {
        color: $color-primary;
      }
    }
  }

  .ng-arrow-wrapper {
    background: transparent;
  }
}

.hosty-input-group {
  display: inline-block;
  align-items: center;

  border: 1px solid #e6e6eb;
  border-radius: 8px;

  background: $color-white;

  &__icon {
    max-width: 32px;
  }

  &__select {
    max-width: 60px;
  }

  & > * {
    display: inline-block;

    vertical-align: middle;
  }

  .form-control {
    max-width: 60px;
    height: 40px;
    padding: 0;
    padding-left: 12px;

    border: none;
    border-radius: 0;

    background: transparent;
  }

  .text-muted {
    position: relative;
    flex-basis: 100%;
    padding-right: 12px;

    font-size: 14px;
    line-height: 18px;
  }

  &.disabled {
    background-color: $bg-disabled;
  }
}

.ng-invalid.ng-touched {
  .hosty-input-group.ng-invalid {
    border-color: $color-error;
    .text-muted {
      color: $color-error;
    }
    .form-control {
      color: $color-error;
    }
  }
}

.form-control.form-control-transparent {
  background: transparent;
}

.layout-mobile {
  .form-group {
    margin-bottom: 12px;
  }

  .ng-select.hosty-select .ng-select-container {
    .ng-arrow-wrapper {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      line-height: 16px;
    }

    .ng-input {
      padding-right: 30px;
    }
  }
}

.select {
  &-months {
    &.ng-select {
      .ng-select-container {
        border: none;

        box-shadow: none;

        .ng-value-container {
          padding: 0;

          .ng-value {
            color: $color-dark;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      &.ng-select-focused {
        .ng-select-container {
          border: none;

          box-shadow: none;
        }
      }

      .ng-arrow-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: 16px;
        padding: 0;

        transition: 0.3s;

        border-radius: 50%;

        background-color: $color-primary-light;

        color: $color-primary;

        .ng-arrow {
          display: flex;
          top: unset;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;

          transition: 0.3s transform;

          border: none;

          &::before {
            content: '\e910';

            font-family: 'icons-font';
            font-size: 9px;
          }
        }
      }

      &.ng-select-opened {
        .ng-arrow-wrapper {
          background-color: $color-primary;

          color: #fff;

          .ng-arrow {
            transform: rotate(180deg);
          }
        }
      }

      .ng-dropdown-panel {
        right: 0;
        left: unset;
        min-width: 150px;

        border: 1px solid $color-divider;
        border-radius: 8px;

        background-color: #ffffff;
        box-shadow: none;

        &::before {
          content: '';

          display: block;
          position: absolute;
          top: -3px;
          right: 3px;
          width: 16px;
          height: 16px;

          transform: rotate(45deg);

          border: 1px solid $color-divider;
          border-radius: 2px;

          background-color: #ffffff;
        }

        .ng-dropdown-panel-items {
          border-radius: 8px;

          background-color: #fff;
        }

        .ng-option {
          display: flex;
          align-items: center;
          padding: 6px 16px;

          background-color: transparent;

          color: $color-dark;
          font-size: 14px;
          line-height: 18px;

          &.ng-option-marked {
            background-color: transparent;
          }

          &.ng-option-selected {
            color: $color-primary;

            &::before {
              content: '\e904';

              margin-right: 8px;

              color: #43959d;
              font-family: 'icons-font';
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}

.layout-mobile {
  .select {
    &-months {
      &.ng-select {
        .ng-value-container {
          .ng-value {
            font-size: 12px;
          }
        }
      }

      .ng-arrow-wrapper {
        margin-left: 12px;
      }
    }
  }
}
